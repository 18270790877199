import React from 'react'
import PageHeader from '../components/PageHeader'

const MissionsScreen = () => {
  const missions = [
    { id: 1, title: "Community-Centric Education", image: "../../Missions/1.png", duration: "1300", description:"Tailoring programs to meet the unique needs of communities in Bangladesh, India, Nepal, Bhutan, Pakistan, Kazakhstan, Mexico, Burundi, Nigeria, South Africa, Ghana, and beyond. We focus on sexual and reproductive health, integrating climate awareness to address the unique challenges posed by changing environments."},
    {
      id: 2, title: "Advocacy for Vulnerable Groups", image: "../../Missions/2.png", duration: "1400", description: "Raising our voice for the rights and well-being of women, children, adolescents, and the LGBTQI community. We strive to create a world that respects and upholds the dignity of every individual." },
    {
      id: 3, title: "Support for Refugees and Migrants", image: "../../Missions/3.png", duration: "1500", description: "Offering a lifeline to refugees navigating the daunting challenges of displacement. From language and cultural integration to providing safe spaces and resources, we help them rebuild their lives with dignity and confidence." },
    {
      id: 4, title: "Breaking Stigmas", image: "../../Missions/4.png", duration: "1600", description: "Through bold advocacy and community engagement, we challenge the norms and prejudices that restrict access to health and education. We believe in the power of informed dialogue to foster acceptance and change." },
    {
      id: 5, title: "Creating Inclusive Community", image: "../../Missions/5.png", duration: "1700", description: "Through our multifaceted approach, we are trying to create an inclusive community, where people will find their personal worth and significance. We keep their identity and personal information private, and we believe that everyone has the right to get the service and take actions. Together, we can make a difference!" },
  ]

  return (
    <>
    <PageHeader title="Our Missions"/>

      <section className="max-w-screen-xl m-auto my-16 px-6">

        {/* 1 */}
        <div data-aos="fade-up" data-aos-duration="1200">
          <div className="pb-2" >
            <h2 className=" text-left text-xl font-bold text-safe-600" >Empowering Voices, Enabling Futures</h2>
          </div>

          <div className="text-sm text-gray-800 text-justify">
            <p >Since 2020, SAFE has been working to improve the lives of people, especially those with special needs and marginalized communities.</p>
            <p>At SAFE (Sexuality Awareness & Freedom Education), we believe in creating a world where every individual has the freedom and knowledge to make informed choices about their sexual and reproductive health. Our mission is to dismantle the barriers imposed by social stigmas and environmental challenges, fostering a world where safety, health, and education are accessible to all, irrespective of gender, sexuality, or geographical boundaries.</p>
            <br />
          </div>
        </div>

        {/* 2 */}
        <div data-aos="fade-up" data-aos-duration="1200">
          <div className="pb-2" >
            <h2 className=" text-left text-xl font-bold text-safe-600" >What We Do</h2>
          </div>

          <div className="text-sm text-gray-800 text-justify">
            <p >SAFE operates at the intersection of sexual health, mental health, and the environmental impacts of climate change. We provide critical education, resources, and support to marginalized communities across continents—from the dense populations of South Asia to the diverse landscapes of Africa and the culturally rich regions of Latin America and Europe.</p>
            <br />
          </div>
        </div>


        {/* 3 */}
        <div data-aos="fade-up" data-aos-duration="1200">
          <div className="pb-2" >
            <h2 className=" text-left text-xl font-bold text-safe-600" >Our Vision</h2>
          </div>

          <div className="text-sm text-gray-800 text-justify">
            <p >A world where every individual—regardless of their origin, identity, or status—enjoys the freedom to live a healthy, informed, and sustainable life. At SAFE, we envision a society that embraces diversity and is equipped to face the challenges of today and tomorrow with resilience and solidarity.</p>
            <br />
          </div>
        </div>

        {/* 4 */}
        <div data-aos="fade-up" data-aos-duration="1200">
          <div className="pb-2" >
            <h2 className=" text-left text-xl font-bold text-safe-600" >Our Approach</h2>
          </div>


          <div className=" py-4 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-12">
            {
              missions?.map(item => {
                return (
                  <>
                    <div key={item?.id} className="bg-white rounded-lg overflow-hidden p-6" data-aos="fade-up" data-aos-duration={item?.duration} >
                      <div className="overflow-hidden ">
                        <img className="w-28 mx-auto object-contain" src={item?.image} alt={item?.id} />
                      </div>

                      {/* title  */}
                      <div className="flex flex-col space-y-2 py-2">
                        <h1 className="text-gray-800 font-semibold text-lg lg:text-xl leading-tight text-center">{item?.title}</h1>
                        <p className="text-sm text-gray-800 text-justify">{item?.description}</p>
                      </div>
                    </div>
                  </>
                )
              })
            }
          </div>
        </div>

   </section>
    </>
  )
}

export default MissionsScreen