import React from 'react'

const PageHeader = ({title}) => {
  return (
    <section className="bg-safe-600 h-72">
          
          <div className="relative top-32">
              <h1 className="text-9xl text-gray-50 font-extrabold text-center opacity-5" data-aos="fade-up" data-aos-duration="1000">{title}</h1>
              <div className="absolute top-20 left-48 right-48" data-aos="fade-up" data-aos-duration="1200">
                  <h2 className=" text-center text-xl md:text-3xl lg:text-4xl font-bold text-white" >{title}</h2>
                  {/* <div className="w-12 h-1 mt-1 mx-auto bg-white rounded-full"></div> */}
              </div>
          </div>
    </section>
  )
}

export default PageHeader