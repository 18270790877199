import React from 'react'
import AboutUs from '../components/HomeScreen/AboutUs'
import PageHeader from '../components/PageHeader'

const AboutScreen = () => {
  return (
    <>
    <PageHeader title="About Us"/>
    <AboutUs />
    </>
  )
}

export default AboutScreen