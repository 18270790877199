import React from 'react'
import Knowledgebase from '../components/HomeScreen/Knowledgebase'
import PageHeader from '../components/PageHeader'

const KnowledgeBaseScreen = () => {
  return (
    <>
    <PageHeader title="Knowledgebase"/>
    <Knowledgebase /> 
    </>
  )
}

export default KnowledgeBaseScreen