import React, { useState } from 'react';
import { FaPhoneAlt } from "react-icons/fa";
import { FaLinkedin, FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import PageHeader from '../components/PageHeader';

const ContactScreen = () => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");


  const submitForm = () => {
    window.open(
      `mailto:smabtahinoor@gmail.com?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(name)} (${encodeURIComponent(
        email
      )}): ${encodeURIComponent(message)}`
    );
  };

  return (
    <>
    <PageHeader title="Contact"/>
      
      <section className="max-w-screen-xl mx-auto px-6 py-8 grid grid-cols-1 lg:grid-cols-2 gap-10">
      
        <div className="col-span-1 lg:col-span-1">

          <div className="flex flex-col space-y-3" data-aos="fade-up"
            data-aos-duration="1200">
            <h1 className="text-safe-600 font-bold text-3xl">Get in touch</h1>
            <p className="text-xs text-gray-500 text-left w-96">Explore how you can contribute to our mission. Visit our website, follow us on social media, or contact us directly to get involved. Every action, no matter how small, has the potential to spark significant change.</p>
            <div>
              <ul className="text-sm text-gray-500 text-justify flex flex-col ">
                <li className="hover:underline cursor-pointer flex flex-row items-center space-x-2"> <FaLocationDot />  <span>Address: Dhaka, P.O. 1334 Road, Bangaladesh</span>  </li>
                <li className="hover:underline cursor-pointer flex flex-row items-center space-x-2 "> <MdEmail /> <span> Email: safe.us@gmail.com</span>  </li>
                <li className="hover:underline cursor-pointer flex flex-row items-center space-x-2"> <FaPhoneAlt /> <span>Phone: 01777777777 </span>   </li>
                <li className="hover:underline cursor-pointer flex flex-row items-center space-x-2">  <FaLinkedin /> <span>Linkedin : safe-us </span>  </li>
              </ul>
            </div>
          </div>

        

        </div>

        <div className="col-span-1 lg:col-span-1" data-aos="fade-up"
          data-aos-duration="1200">  
          <form onSubmit={submitForm}>
          <fieldset className="flex flex-col space-y-2 ">
            <div className="flex flex-col space-y-2">
              <label htmlFor="contactName" className="text-sm">
                Name <span className="required text-sm">*</span>
              </label>
              <input
                  className="p-4 outline-none"
                type="text"
                defaultValue=""
                value={name}
                size="35"
                id="contactName"
                name="contactName"
                onChange={(e) => setName(e.target.value)}
              />
            </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="contactEmail" className="text-sm">
                Email <span className="required">*</span>
              </label>
              <input
                  className="p-4 outline-none"
                type="email"
                defaultValue=""
                value={email}
                size="35"
                id="contactEmail"
                name="contactEmail"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="contactSubject" className="text-sm">Subject</label>
              <input
                  className="p-4 outline-none"
                type="text"
                defaultValue=""
                value={subject}
                size="28"
                id="contactSubject"
                name="contactSubject"
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="contactMessage" className="text-sm">
                Message <span className="required">*</span>
              </label>
              <textarea
                  className="p-4 outline-none"
                cols="50"
                rows="15"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                id="contactMessage"
                name="contactMessage"
              ></textarea>
            </div>

            <div>
                <button
                  className="bg-safe-600 px-4 border border-white hover:border-safe-600 py-2 text-sm text-white transition duration-300 rounded-lg hover:bg-white hover:text-safe-600"
                  onClick={submitForm} type="submit"
                >
                  Submit
                </button>
            
            </div>
          </fieldset>
        </form></div>


      </section>

      {/* <div id="message-warning"> Error boy</div>
      <div id="message-success">
        <i className="fa fa-check"></i>Your message was sent, thank you!
        <br />
      </div> */}

    </>
  )
}

export default ContactScreen