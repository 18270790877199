import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import SectionTitle from '../SectionTitle';

const Faq = () => {
  const location = useLocation();
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    axios.get("../../../db/faq.json").then((response) => {
      setFaqs(response.data);
    });

  },[])

  // const faqs = [
  //   {
  //     question: "dsgsdgsdhhsdh",
  //     answer:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //   },
  //   {
  //     question: "dshsdhsdhshsh",
  //     answer:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //   },
  //   {
  //     question:
  //       "dgsdhsddddddddddddddddddddddddddddd",
  //     answer:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //   },
  //   {
  //     question:
  //       "dgsdhsddddddddddddddddddddddddddddd",
  //     answer:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //   },
  //   {
  //     question:
  //       "dgsdhsddddddddddddddddddddddddddddd",
  //     answer:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //   }
  // ];


  return (
    <section className="max-w-screen-xl m-auto my-16 px-6">

      {location?.pathname === "/" && <SectionTitle title="FAQ" />}

        {/* maincontent  */}

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 w-full gap-20 pt-12">
          {/* faqs  */}
          <div className="col-span-1 md:col-span-2 lg:col-span-3 flex flex-col space-y-5 "   data-aos="fade-up"
              data-aos-duration="1200">
            {faqs.map((faq, index) => (
              <Accordion
                key={index}
                className="py-4 border-none rounded-xl shadow-none faqcard  "
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <h1 className="text-safe-600 font-bold">{faq?.question}</h1>
                </AccordionSummary>
                <AccordionDetails className="text-sm text-gray-700">
                  {faq?.answer}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>

          {/* vectors */}
          <div className="col-span-1 md:col-span-1 lg:col-span-2">
            <img
              className="object-cover"
              src="../../../faq.svg"
              alt="faq"
              data-aos="fade-up"
              data-aos-duration="1200"
            />
          </div>
        </div>

     
    </section>
  )
}

export default Faq