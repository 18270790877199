import React from 'react'
import AboutUs from '../components/HomeScreen/AboutUs'
import Activites from '../components/HomeScreen/Activites'
import Faq from '../components/HomeScreen/Faq'
import HeroSection from '../components/HomeScreen/HeroSection'
import Knowledgebase from '../components/HomeScreen/Knowledgebase'
import Missions from '../components/HomeScreen/Missions'

const HomeScreen = () => {
    return (
        <>
            <HeroSection />
            <AboutUs />
            <Activites />
            <Missions />
            {/* <Events /> */}
            <Knowledgebase />
            <Faq />
        </>
    )
}

export default HomeScreen