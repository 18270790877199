import React from 'react'

const SectionTitle = ({title}) => {
  return (
    <div className="relative mb-12 lg:mb-0">
      <h1 className="lg:text-9xl text-3xl text-gray-100 font-extrabold text-center opacity-20" data-aos="fade-up" data-aos-duration="1000">{title}</h1>
      <div className="absolute lg:top-20 lg:left-48 lg:right-48 top-3 left-0 right-0" data-aos="fade-up" data-aos-duration="1200">
              <h2 className=" text-center text-2xl md:text-3xl lg:text-4xl font-bold text-safe-600" >{title}</h2>
        <div className="w-12 h-1 mt-1 mx-auto bg-safe-600 rounded-full"></div>
      </div>
    </div>
  )
}

export default SectionTitle
