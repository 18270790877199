import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/NavBar";
import AboutScreen from "./pages/AboutScreen";
import ActivitesScreen from "./pages/ActivitesScreen";
import ActivitesSingle from "./pages/ActivitesSingle";
import ContactScreen from "./pages/ContactScreen";
import EventsScreen from "./pages/EventsScreen";
import FAQScreen from "./pages/FAQScreen";
import HomeScreen from "./pages/HomeScreen";
import KnowledgeBaseScreen from "./pages/KnowledgeBaseScreen";
import KnowledgeBaseSingle from "./pages/KnowledgeBaseSingle";
import MissionsScreen from "./pages/MissionsScreen";
import TeamScreen from "./pages/TeamScreen";

function App() {
  return (
    <Router>
      <Navbar />
        <Routes>
          <Route
            path="/"
            element={<HomeScreen />}
          />
          <Route
            path="/about"
            element={<AboutScreen />}
          />
          <Route
            path="/missions"
            element={<MissionsScreen />}
          />
          <Route
            path="/events"
            element={<EventsScreen />}
          />
          <Route
            path="/activites"
            element={<ActivitesScreen />}
          />
          <Route
            path="/activites/:id"
            element={<ActivitesSingle />}
          />
          <Route
            path="/faq"
            element={<FAQScreen />}
          />
          <Route
            path="/team"
            element={<TeamScreen />}
          />
          <Route
            path="/contact"
            element={<ContactScreen />}
          />
          <Route
          path="/knowledgebase"
            element={<KnowledgeBaseScreen />}
          />
          <Route
          path="/knowledgebase/:id"
            element={<KnowledgeBaseSingle />}
          />
        </Routes>

        <Footer />
    </Router>
  );
}

export default App;
