import React from 'react';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import SectionTitle from '../SectionTitle';

const Missions = () => {
  const location = useLocation()

  const missions = [
    { id: 1, title: "Community-Centric Education", image: "../../../Missions/1.png", duration: "1300" },
    { id: 2, title: "Advocacy for Vulnerable Groups", image: "../../../Missions/2.png", duration: "1400" },
    { id: 3, title: "Support for Refugees and Migrants", image: "../../../Missions/3.png", duration: "1500" },
    { id: 4, title: "Breaking Stigmas", image: "../../../Missions/4.png", duration: "1600" },
    { id: 5, title: "Creating Inclusive Community", image: "../../../Missions/5.png", duration: "1700" },
  ]
  return (
      <section className="max-w-screen-xl m-auto my-16">

        {/* title  */}
      {location?.pathname === "/" && <SectionTitle title="Our Missions" />}
         

         <div className="px-6 py-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-x-12">
              {
                missions?.map(item => {
                  return(
                    <Link to="/missions" key={item?.id}>
                      <div  className="bg-white rounded-lg overflow-hidden p-6" data-aos="fade-up" data-aos-duration={item?.duration} >
                      <div className="overflow-hidden ">
                          <img className="w-28 mx-auto object-contain" src={item?.image} alt={item?.id} />
                      </div>

                      {/* title  */}
                      <div className="pt-2">
                        <h1 className="text-gray-800 font-semibold text-lg leading-tight text-center cursor-pointer">{item?.title}</h1>
                      </div>
                    </div>
                    </Link>
                  )
                })
              }
         </div>

      {location?.pathname === "/" && (
        <Link to="/missions">
          <div className="flex flex-row items-center justify-center cursor-pointer">
            <span className="text-xs text-gray-600 hover:underline">See More</span>
            <MdOutlineKeyboardArrowRight className="text-sm text-gray-600" />
          </div></Link>
      )}
        
    </section>
  )
}

export default Missions