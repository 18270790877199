import React from 'react'
import Activites from '../components/HomeScreen/Activites'
import PageHeader from '../components/PageHeader'

const ActivitesScreen = () => {
  return (
    <>
    <PageHeader title="Activites"/>
    <Activites />
    </>
  )
}

export default ActivitesScreen