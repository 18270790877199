import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PageHeader from '../components/PageHeader';

const ActivitesSingle = () => {
    const { id } = useParams();
    const [activity, setActivity] = useState({});
    const [data,setData] = useState([])

    useEffect(() => {
        axios.get(".././../db/activities.json").then((response) => {
            const arr = response.data; 
            const item = arr.find((obj) => obj.id == id);
            setData(response?.data);

            setActivity(item);
        });

    }, [data])


    return (
        <main>

            {/* header  */}
           <PageHeader title="Activites"/>
            <section className="py-12 max-w-screen-xl mx-auto px-6">
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-12">
                    <div className="col-span-1 lg:col-span-2">
                        <img className="w-full h-auto object-cover rounded-lg" src={activity?.image} alt={activity?.id} />
                    </div>

                    <div className="flex flex-col space-y-2 col-span-1 lg:col-span-1" >
                        <h1 className="text-2xl font-bold text-safe-600">{activity?.title}</h1>
                        <p className="text-xs text-gray-500 text-justify">{activity?.description}</p>
                    </div>
                </div>


                {/* others  */}
                <div className="pt-16" data-aos="fade-up" data-aos-duration="1200">
                    <h2 className=" text-center text-2xl md:text-3xl lg:text-4xl font-bold text-safe-600" >Other Activites</h2>
                    <div className="w-12 h-1 mt-1 mx-auto bg-safe-600 rounded-full"></div>
                </div>
                {
                    <div className=" py-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-12">
                        {
                            data?.filter(item=> item?.id != id)?.map(item => {
                                return (
                                    <Link to={`/activites/${item?.id}`} key={item?.id}>
                                        <div className="bg-white rounded-lg overflow-hidden" data-aos="fade-up" data-aos-duration={item?.duration}>
                                            <div className="overflow-hidden cursor-pointer h-48">
                                                <img className="w-full h-full object-cover hover:scale-125 duration-300 transition" src={item?.image} alt={item?.id} />
                                            </div>

                                            {/* title  */}
                                            <div className="flex flex-col justify-center p-6 space-y-2">
                                                <h1 className="text-gray-800 font-semibold text-2xl text-center cursor-pointer">{item?.title}</h1>
                                                <p className="text-gray-500 text-xs text-justify">{item?.s_description}<Link className="underline pl-1" to={`/activites/${item?.id}`}>Read More</Link></p>

                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                }
            </section>

        </main>
    )
}

export default ActivitesSingle