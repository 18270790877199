import React from 'react'
import Events from '../components/HomeScreen/Events'
import PageHeader from '../components/PageHeader'

const EventsScreen = () => {
  return (
    <>
    <PageHeader title="Our Events"/>
    <Events />
    </>
  )
}

export default EventsScreen