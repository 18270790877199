import React from 'react'
import Faq from '../components/HomeScreen/Faq'
import PageHeader from '../components/PageHeader'

const FAQScreen = () => {
  return (
    <>
    <PageHeader title="FAQ"/>
    <Faq />
    </>
  )
}

export default FAQScreen