import AOS from "aos";
import "aos/dist/aos.css"; // 
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
AOS.init();


root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

