import React from 'react';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import SectionTitle from '../SectionTitle';

const Activites = () => {
  const location = useLocation()


  const activites = [
    {
      id: 1, title: "Project SafePath: Seminar on Sexual Education in High School", description: "The primary objective of Project SafePath is to provide comprehensive and accurate sexual education to high school students. This project aims to empower students, educators and school staff with knowledge about sexual health, consent, relationships, and personal safety. Our activities include interactive seminars, developing and distributing comprehensive educational materials such as pamphlets, booklets, and multimedia resources, peer discussions, and counseling services. We organize these seminars every month in different schools and have successfully organized four seminars so far.", s_description: "The primary objective of Project SafePath is to provide comprehensive and accurate sexual education to high school students. This project aims to empower ...", image:"../../../activites/1.jpg", duration:"1300"},
    {
      id: 2, title: "Comprehensive STD Intervention among LGBTQ Community and Sex Workers", description: "The 'Talk.Test.Treat.' project is a comprehensive initiative designed to address the high rates of sexually transmitted diseases (STDs) among the LGBTQ community and sex workers. The project aims to increase awareness, facilitate testing, and ensure access to treatment, thereby improving the overall sexual health of these vulnerable populations.The project has already provided services to 200 individuals on a pilot basis, demonstrating its feasibility and initial success.Our activities include awareness and outreach, testing and treatment services, as well as monitoring and evaluation. We have a dedicated volunteer team that includes health professionals serving the community", s_description: "The 'Talk.Test.Treat.' project is a comprehensive initiative designed to address the high rates of sexually transmitted diseases (STDs) among the LGBTQ community and sex workers...", image:"../../../activites/2.jpg", duration:"1400"},
    {
      id: 3, title: "Get Yourself Tested for STDs in Slums and Refugee Camps", description: "The 'GYT: Get Yourself Tested for STDs in Slums and Refugee Camps 'project aims to address the critical issue of sexually transmitted diseases (STDs) among vulnerable populations living in slums and refugee camps. The primary goal of the project is to provide accessible and confidential STD testing services including Confidential Counseling and Treatment Referrals. Our trained healthcare providers have tested more than 500 individuals in the slums of Dhaka and the world's largest refugee camp, Kutupalong Rohingya Camp, on a pilot basis. ", s_description: "The 'GYT: Get Yourself Tested for STDs in Slums and Refugee Camps 'project aims to address the critical issue of sexually transmitted diseases (STDs) among vulnerable populations living in slums and refugee camps...", image:"../../../activites/3.png", duration:"1500"},
    {
      id: 4, title: "Project Empowering Menstrual Hygiene: Sanitary Napkins Distribution in School", description: "The primary objective of this project is to ensure access to sanitary napkins for female students in schools, aiming to promote menstrual hygiene and prevent disruptions in education due to menstruation.Firstly, we conduct surveys or interviews to comprehensively understand the current menstrual hygiene practices among students. Next, we assess the availability and affordability of sanitary products within schools. Based on this evaluation, we select suitable schools for intervention.Subsequently, we conduct training sessions for teachers or designated staff members on proper distribution methods and menstrual hygiene education.This includes instruction on the correct usage and disposal of sanitary products.Additionally, we ensure a consistent supply of sanitary napkins to schools on a monthly basis.In rural Bangladesh, we already have distributed 2500 units to support menstrual hygiene management among students.", s_description: "The primary objective of this project is to ensure access to sanitary napkins for female students in schools, aiming to promote menstrual hygiene and prevent disruptions in education due to menstruation...", image:"../../../activites/4.jpeg", duration:"1600"},
  ]
  return (
      <section className="max-w-screen-xl m-auto my-16">

        {/* title  */}
      {location?.pathname === "/" && <SectionTitle title="Activites" />}

      {location?.pathname === "/" ? (
        <div className="px-6 py-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-12">
          {
            activites?.slice(0,3)?.map(item => {
              return (
                <Link to={`/activites/${item?.id}`} key={item?.id}>
                  <div  className="bg-white rounded-lg overflow-hidden" data-aos="fade-up" data-aos-duration={item?.duration}>
                    <div className="overflow-hidden cursor-pointer h-48">
                      <img className="w-full h-full object-cover hover:scale-125 duration-300 transition" src={item?.image} alt={item?.id} />
                    </div>

                    {/* title  */}
                    <div className="flex flex-col justify-center p-6 space-y-2">
                      <h1 className="text-gray-800 font-semibold text-2xl text-center cursor-pointer">{item?.title}</h1>
                      <p className="text-gray-500 text-xs text-justify">{item?.s_description}<Link className="underline pl-1" to={`/activites/${item?.id}`}>Read More</Link></p>

                    </div>
                  </div>
                </Link>
              )
            })
          }
        </div>
      ) : (
          <div className="px-6 py-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-12">
            {
              activites?.map(item => {
                return (
                  <Link to={`/activites/${item?.id}`} key={item?.id}>
                    <div  className="bg-white rounded-lg overflow-hidden" data-aos="fade-up" data-aos-duration={item?.duration}>
                      <div className="overflow-hidden cursor-pointer h-48">
                        <img className="w-full h-full object-cover hover:scale-125 duration-300 transition" src={item?.image} alt={item?.id} />
                      </div>

                      {/* title  */}
                      <div className="flex flex-col justify-center p-6 space-y-2">
                        <h1 className="text-gray-800 font-semibold text-2xl text-center cursor-pointer">{item?.title}</h1>
                        <p className="text-gray-500 text-xs text-justify">{item?.s_description}<Link className="underline pl-1" to={`/activites/${item?.id}`}>Read More</Link></p>

                      </div>
                    </div>
                  </Link>
                )
              })
            }
          </div>
      )}
         



      {location?.pathname === "/" && (
        <Link to="/activites">
          <div className="flex flex-row items-center justify-center cursor-pointer">
            <span className="text-xs text-gray-600 hover:underline">See More</span>
            <MdOutlineKeyboardArrowRight className="text-sm text-gray-600" />
          </div></Link>
      )}
    </section>
  )
}

export default Activites