import React from 'react';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import SectionTitle from '../SectionTitle';

const Events = () => {
  const location = useLocation()


  const events = [
    { id: 1, title: "XYZXyzsdsg", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem ", image: "https://images.unsplash.com/photo-1717155736971-b53c6dfd940f?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", duration: "1300" },
    { id: 2, title: "XYZXyzsdsg", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.", image: "https://images.unsplash.com/photo-1717436879909-2f5423213541?q=80&w=1738&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", duration: "1400" },
    { id: 3, title: "XYZXyzsdsg", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem ", image: "https://images.unsplash.com/photo-1717297808345-b740e9846158?q=80&w=1712&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", duration: "1500" },
    { id: 4, title: "XYZXyzsdsg", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem ", image: "https://images.unsplash.com/photo-1717457781863-5277d4dafce6?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", duration: "1600" },
    { id: 5, title: "XYZXyzsdsg", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem ", image: "https://images.unsplash.com/photo-1716369415883-db404f0a8425?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", duration: "1700" },
    { id: 6, title: "XYZXyzsdsg", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem ", image: "https://images.unsplash.com/photo-1717267918107-469f3be10d4e?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", duration: "1800" },
  ]
  return (
    <section className="max-w-screen-xl m-auto my-16">

      {/* title  */}
      {location?.pathname === "/" && <SectionTitle title="Events" />}

      <div className="px-6 py-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        {
          events?.map(item => {
            return (
              <>
                <div key={item?.id} className="bg-white rounded-lg overflow-hidden relative" data-aos="fade-up" data-aos-duration={item?.duration}>
                  <div className="overflow-hidden cursor-pointer rounded-lg">
                    <img className="w-full h-48 object-cover hover:scale-125 duration-300 transition" src={item?.image} alt={item?.id} />
                    {/* title  */}
                    <div className="flex flex-col space-y-1 absolute left-0 bottom-0 p-6">
                      <h1 className="text-white font-semibold text-xl cursor-pointer">{item?.title}</h1>
                      <p className="text-gray-200 text-xs text-justify">{item?.description}</p>
                    </div>
                  </div>

                 
                </div>
              </>
            )
          })
        }
      </div>

      {location?.pathname === "/" && (
        <Link to="/events">
          <div className="flex flex-row items-center justify-center cursor-pointer">
            <span className="text-xs text-gray-600 hover:underline">See More</span>
            <MdOutlineKeyboardArrowRight className="text-sm text-gray-600" />
          </div></Link>
      )}

     
    </section>
  )
}

export default Events