import React from 'react';
import { FaPhoneAlt } from "react-icons/fa";
import { FaLinkedin, FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { Link } from 'react-router-dom';

const Footer = () => {
     return (
          <>

               <footer className="bg-white py-8 mt-6" data-aos="fade-up" data-aos-duration="1400">
                    {/* <img src="../../footer.png" className="w-full" alt="" /> */}
                    {/* <div className=" mx-auto text-center sm:text-left"> */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 max-w-screen-xl m-auto px-6">
                         <div className="cols-span-1 flex flex-col space-y-2">
                              <Link to="/"> <img src="../../SAFE_blue.png" alt="logo" className="w-28 h-16  -ml-2 scale-110 object-contain" /></Link>
                              <p className="text-sm text-gray-500 text-justify"> Be a part of a transformational journey. With your support, we can expand our reach, enhance our programs, and deepen our impact. Together, we can build a future that cherishes freedom, equality, and health for all. </p>

                         </div>
                         <div className="cols-span-1 flex flex-col space-y-4 pt-1">
                              <div>
                                   <h2 className="text-xl font-bold text-safe-600"> Our Missions </h2>
                                   <div className="w-12 h-1 mt-1 bg-safe-600 rounded-full"></div>
                              </div>
                              <ul className="text-sm text-gray-500 text-justify flex flex-col space-y-2 ">
                                   <li className="hover:underline cursor-pointer"> <Link to="/missions"> Community-Centric Education</Link> </li>
                                   <li className="hover:underline cursor-pointer"> <Link to="/missions">Advocacy for Vulnerable Groups </Link> </li>
                                   <li className="hover:underline cursor-pointer"> <Link to="/missions">Support for Refugees and Migrants </Link> </li>
                                   <li className="hover:underline cursor-pointer"> <Link to="/missions">Breaking Stigmas  </Link> </li>
                                   <li className="hover:underline cursor-pointer"> <Link to="/missions">Creating Inclusive Community </Link> </li>
                              </ul>
                         </div>

                         <div className="cols-span-1 flex flex-col space-y-4 pt-1">
                              <div>
                                   <h2 className="text-xl font-bold text-safe-600"> Imporant Links </h2>
                                   <div className="w-12 h-1 mt-1 bg-safe-600 rounded-full"></div>
                              </div>
                              <ul className="text-sm text-gray-500 text-justify flex flex-col space-y-2 ">
                                   <li className="hover:underline cursor-pointer"> <Link to="/activites"> Activites </Link> </li>
                                   <li className="hover:underline cursor-pointer"> <Link to="/knowledgebase"> Knowledgebase</Link> </li>
                                   <li className="hover:underline cursor-pointer"> <Link to="/faq"> FAQ </Link> </li>
                                   <li className="hover:underline cursor-pointer"> <Link to="/team"> Team </Link> </li>
                                   <li className="hover:underline cursor-pointer"> <Link to="/contact"> Contact </Link> </li>
                              </ul>
                         </div>

          

                         <div className="cols-span-1 flex flex-col space-y-4 pt-1">
                              <div>
                                   <h2 className="text-xl font-bold text-safe-600"> Contact </h2>
                                   <div className="w-12 h-1 mt-1 bg-safe-600 rounded-full"></div>
                              </div>
                              <ul className="text-sm text-gray-500 text-justify flex flex-col space-y-2 ">
                                   <li className="hover:underline cursor-pointer flex flex-row items-center space-x-2"> <FaLocationDot />  <span>Address: Dhaka, P.O. 1334 Road, Bangaladesh</span>  </li>
                                   <li className="hover:underline cursor-pointer flex flex-row items-center space-x-2 "> <MdEmail /> <span> Email: safe.us@gmail.com</span>  </li>
                                   <li className="hover:underline cursor-pointer flex flex-row items-center space-x-2"> <FaPhoneAlt /> <span>Phone: 01777777777 </span>   </li>
                                   <li className="hover:underline cursor-pointer flex flex-row items-center space-x-2">  <FaLinkedin /> <span>Linkedin : safe-us </span>  </li>
                              </ul>
                         </div>
                         {/* <div className="cols-span-1">
                              <h2 className="text-xl sm:text-2xl mb-5"> Community </h2>
                              <ul className="leading-10">
                                   <li> <Link to="/"> Blog Category </Link> </li>
                                   <li> <Link to="/"> Blog Author </Link> </li>
                                   <li> <Link to="/"> Blog </Link> </li>
                                   <li> <Link to="/"> Blog Details </Link> </li>
                              </ul>
                         </div> */}
                         {/* <div className="cols-span-1">
                              <h2 className="text-xl sm:text-2xl mb-5"> Contact </h2>
                              <ul className="leading-10">
                                   <li> <Link to="/"> Dhaka, P.O. 1334 Road, Bangaladesh </Link> </li>
                                   <li> <Link to="/"> BD Author </Link> </li>
                                   <li> <Link to="/"> xyz@gmail.com </Link> </li>
                                   <li> <Link to="/"> Phone: 01777777777 </Link> </li>
                              </ul>
                         </div> */}
                    </div>

                    {/* <hr className="my-16" /> */}

                    <div className="text-gray-500 flex flex-row items-center justify-center pt-6  ">
                         <p className="text-sm text-gray-500 "> &copy; Copyright - 2024 - Developed by <span className="text-gray-500 "> SAFE DEV TEAM </span> </p>

                         {/* <p> Privacy Policy | Term of Service </p> */}
                    </div>

                    {/* </div> */}
               </footer>
          </>
     )
}

export default Footer