import axios from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';

const KnowledgeBaseSingle = () => {
    const { id } = useParams();
    const [blogData, setBlogData] = React.useState({})

    React.useEffect(() => {
        axios.get(`https://safe-us-server.vercel.app/api/blogs/${id}`)
            .then(res => {
                setBlogData(res?.data);

            })

    }, [id])

  return (
   <main>

    {/* header  */}
          <section className="bg-safe-500">

              <div className=" max-w-screen-xl mx-auto">
                  <img className="w-full h-full object-cover" src={blogData?.image} alt={blogData?.id} />
                  
              </div>
          </section>

          <section className="py-12 max-w-screen-xl mx-auto px-6">
             
              <div className="text-justify" dangerouslySetInnerHTML={{ __html: blogData?.description }}></div>
          </section>

   </main>
  )
}

export default KnowledgeBaseSingle