import React, { useState } from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [mobileNav, setMobileNav] = useState(false);
  const [header, setHeader] = useState(false);


  const menu = [
    { id: 1, text: "About", to: "/about" },
    { id: 2, text: "Our Missions", to: "/missions" },
    // { id: 3, text: "Events", to: "/events" },
    { id: 4, text: "Activites", to: "/activites" },
    { id: 5, text: "Knowledgebase", to: "/knowledgebase " },
    { id: 6, text: "Team", to: "/team" },
    { id: 7, text: "FAQ", to: "/faq" },
    { id: 8, text: "Contact", to: "/contact" },
  ];

  const handleClick = () => {
    setMobileNav(!mobileNav);
  };

  const changeHeader = () => {
    if (window.scrollY >= 80) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  };

  window.addEventListener("scroll", changeHeader);

  return (
    <header
      className={
        !header
          ? "fixed w-full z-10 py-1 bg-transparent"
          : "bg-white z-10 fixed w-full py-1"
      }
    >
      <nav className="flex items-center py-3 max-w-screen-xl m-auto px-6">
        <div className="flex items-center space-x-1 flex-grow">
          <Link to="/">
           {header ? (<>
              <img src="../../SAFE_blue.png" alt="logo" className="w-40 h-16 -ml-5 scale-110 object-contain" />
           </>) : (
            <>
                  <img src="../../SAFE_white.png" alt="logo" className="w-40 h-16 -ml-5 scale-110 object-contain" />
            </>
           )}
          </Link>
        
        </div>

        <div className="hidden md:flex lg:flex space-x-3">
          <ul className="flex items-center space-x-4">
            {menu.map((item) => (
              <li key={item.id}>
                <Link to={item.to} className={header ? "text-gray-600 text-sm" : " text-white text-sm"}>
                  {item.text}
                </Link>
              </li>
            ))}
          </ul>

          {/* <div className="text-white flex flex-row space-x-3">
            <button
              className="bg-safe-600 px-4 border border-white hover:border-safe-600 py-2 text-sm text-white transition duration-300 rounded-lg hover:bg-white hover:text-safe-600"
            >
              Login
            </button>
          </div> */}
        </div>

        <div className="block md:hidden lg:hidden">
          <HiMenuAlt3
            className={header ? "w-12 h-12 text-gray-700  cursor-pointer rounded-lg p-2 transform transition duration-200 hover:scale-110" : "text-white w-12 h-12   cursor-pointer  p-2 transform transition duration-200 hover:scale-110"}
            onClick={handleClick}
          />
        </div>
      </nav>

      {mobileNav && (
        <div data-aos="zoom-in">
          <nav className="bg-white mx-6 mt-2 rounded-lg border border-gray-300 py-4 px-4 block md:hidden lg:hidden">
            <ul>
              {menu.map((item) => (
                <Link
                  to={item.to}
                  key={item.id}
                  className="text-gray-600 text-sm cursor-pointer"
                >
                  <li className="py-2 px-3 w-full hover:bg-gray-200 transition duration-300 cursor-pointer">
                    {item.text}
                  </li>
                </Link>
              ))}
            </ul>
            {/* <div className="px-3 py-2">
              
                <button
                className="bg-safe-600 px-3 py-2 border border-white hover:border-safe-600  text-sm text-white transition duration-300 rounded-lg hover:bg-white hover:text-safe-600"
                
                >
                  Login
                </button>
              
            </div> */}
          </nav>
        </div>
      )}

    </header>
  );
};

export default Navbar;
