import React from "react";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/faq");
  };

  return (
    <main>
      <section className="hero_bg">
        <div className="max-w-screen-xl m-auto">
          {/* parent div  */}
          <div className="z-20 flex flex-col px-6 pt-32 lg:items-center lg:justify-between md:flex-col lg:flex-row lg:pt-28">
            {/* main text and button */}
            <div
              data-aos="fade-right"
              data-aos-duration="1200"
              className="flex flex-col items-start space-y-8 "
            >
              <h1 className="text-white text-6xl lg:text-7xl  font-bold  tracking-tight leading-tight">
                Let's Create_<br /> Sexual Awarness<br /> Together{" "}
              </h1>
              <p className="text-sm text-gray-200 w-2/3">Be a part of a transformational journey. With your support, we can expand our reach, enhance our programs, and deepen our impact. Together, we can build a future that cherishes freedom, equality, and health for all.</p>
              <button
                className="bg-white px-5 py-3 text-base text-safe-600 border border-safe-600 transition duration-300 rounded-lg hover:bg-safe-600 hover:text-white hover:border-white font-semibold"
                onClick={handleNavigation}
              >
                Explore Now
              </button>
            </div>

            {/* vector gfx  */}
            <div>

              <div data-aos="fade-left" data-aos-duration="1200">
                <img
                  className="relative hero_section_vector object-cover"
                  src="../../../heroimg.png"
                  alt="hero section vector"
                />
              </div>
            </div>

          </div>
        </div>
     </section>


      <div className="relative -top-28 -z-10">
        {/* <img src="../../../herowave.svg" alt="wave" /> */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#1d4ed8" fill-opacity="1" d="M0,192L80,181.3C160,171,320,149,480,165.3C640,181,800,235,960,240C1120,245,1280,203,1360,181.3L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>
      </div>
    </main>
  );
};

export default HeroSection;
