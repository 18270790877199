import React from 'react';
import { useLocation } from 'react-router-dom';
import SectionTitle from '../SectionTitle';

const AboutUs = () => {
  const location = useLocation()

  return (
      <section className="max-w-screen-xl m-auto">

        {/* title  */}
      {location?.pathname === "/" && <SectionTitle title="About Us" />}
          

         <div className="px-6 py-8 grid grid-cols-1 lg:grid-cols-5 gap-x-12">
              {/* left part  */}
              {/* video  */}
        <div className=" lg:col-span-2" data-aos="fade-up" data-aos-duration="1300">
          <img  className="-mt-16" src="../../../aboutus.svg" alt="aboutus" />

              </div>
        <div className="lg:col-span-3" data-aos="fade-up" data-aos-duration="1300">
          <p className="text-sm text-gray-800 text-justify">Welcome to Sexual Awareness and Freedom Education (SAFE), an organization, home in Bangladesh, dedicated to providing comprehensive sex education worldwide. At SAFE, we believe that knowledge is empowerment. In addition to our primary focus on sex education, we are also dedicated to addressing the intersection of sexual health and climate change, recognizing the rising concerns that climate change causes to sexual health.</p>

          <p className="text-sm text-gray-800 text-justify">
            Our knowledge base and FAQs provide essential information on a wide range of topics related to sexual health and climate change. We cover everything from basic anatomy and contraception to consent and healthy relationships. SAFE is inclusive and welcoming to everyone who seeks our services, regardless of gender, belief, sexual orientation, and identity. We provide a safe space for individuals to learn and grow, without fear of judgment or discrimination.</p>

          <p className="text-sm text-gray-800 text-justify">
            We organize campaigns to raise awareness about sexual health issues, promote safe practices, and advocate for better education and resources. Moreover, we offer support and resources for victims of sexual assault. Our team is dedicated to providing compassionate assistance they need to heal and recover.
</p>
          <p className="text-sm text-gray-800 text-justify">
            We believe the best service is possible when the concerned people get the service in their mother language. That is why, we are providing our service in many languages apart from English, including Bengali, Urdu, Hindi, Arabic, Afrikaans, etc.

            At SAFE, we recognize the importance of addressing climate change and its effects on sexual health. Our educational materials and discussions explore the connections between environmental changes and health, aiming to raise awareness and promote a healthier future.

</p>



              </div>
         </div>
    </section>
  )
}

export default AboutUs