import axios from "axios";
import React, { useEffect, useState } from 'react';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import SectionTitle from '../SectionTitle';

const Knowledgebase = () => {
  const location = useLocation()
  const [knowledgebase,setKnowledgeBase] = useState([])


  useEffect(() => {
    axios.get("https://safe-us-server.vercel.app/api/blogs").then((response) => {
      setKnowledgeBase(response.data);
    });
  },[])

 
  return (
      <section className="max-w-screen-xl m-auto my-16">

        {/* title  */}
      {location?.pathname === "/" && <SectionTitle title="Knowledgebase" />}

      {
        location?.pathname === "/" ? (
          <div className="px-6 py-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-12">
            {
              knowledgebase?.slice(0,3)?.map(item => {
                return (
                  <Link to={`/knowledgebase/${item?.id}`} key={item?.id}>
                    <div  className="bg-white rounded-lg overflow-hidden" data-aos="fade-up" data-aos-duration="1400">
                      <div className="overflow-hidden cursor-pointer">
                        <img className="w-full object-cover hover:scale-125 duration-300 transition" src={item?.image} alt={item?.id} />
                      </div>

                      {/* title  */}
                      <div className="flex flex-col justify-center p-6 space-y-2">
                        <h1 className="text-gray-800 font-semibold text-2xl text-center cursor-pointer">{item?.title}</h1>
                        {/* <div className="text-gray-500 text-xs text-justify" dangerouslySetInnerHTML={{ __html: item?.description }}></div> */}
                        <p className="text-gray-500 text-xs text-center underline">
                          <Link to={`/knowledgebase/${item?.id}`}>Read this article</Link>
                        </p>
                      </div>
                    </div>
                  </Link>
                )
              })
            }
          </div>

        ) : (
            <div className="px-6 py-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-12">
              {
                knowledgebase?.map(item => {
                  return (
                    <Link to={`/knowledgebase/${item?.id}`} key={item?.id}>
                      <div  className="bg-white rounded-lg overflow-hidden" data-aos="fade-up" data-aos-duration="1400">
                        <div className="overflow-hidden cursor-pointer">
                          <img className="w-full object-cover hover:scale-125 duration-300 transition" src={item?.image} alt={item?.id} />
                        </div>

                        {/* title  */}
                        <div className="flex flex-col justify-center p-6 space-y-2">
                          <h1 className="text-gray-800 font-semibold text-2xl text-center cursor-pointer">{item?.title}</h1>
                          {/* <div className="text-gray-500 text-xs text-justify" dangerouslySetInnerHTML={{ __html: item?.description }}></div> */}
                          <p className="text-gray-500 text-xs text-center underline">
                            <Link to={`/knowledgebase/${item?.id}`}>Read this article</Link>
                          </p>
                        </div>
                      </div>
                    </Link>
                  )
                })
              }
            </div>


        )
      }

         


      {location?.pathname === "/" && (
        <Link to="/knowledgebase">
          <div className="flex flex-row items-center justify-center cursor-pointer">
            <span className="text-xs text-gray-600 hover:underline">See More</span>
            <MdOutlineKeyboardArrowRight className="text-sm text-gray-600" />
          </div></Link>
      )}
    </section>
  )
}

export default Knowledgebase